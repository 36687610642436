import { axiosPrivate } from "../../common/axiosPrivate";
import getAPIMap from "../../routes/ApiUrls";
const IP = process.env.REACT_APP_IP;

/**
 * This method is used to get the Alert Details in Alert
 */
export async function getAlertDetails(option) {
  let url = getAPIMap("getAlert");
  console.log("option", option);
  //let url = "https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/alerts/list";
  url += "?role=" + option.queryKey[1];
  console.log("url", url);
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used to update the Alert Details in Alert
 */
export async function updateAlertDetails(option) {
  let url = getAPIMap("updateAlerts");

  let response = axiosPrivate.post(url, option.updatedPayload);
  return response;
}

/**
 * This method is used for saving JD
 * @param {*} options
 * @returns
 */
export async function saveAdminJob(options) {
  console.log("Option", options);
  let url = getAPIMap("saveAdminJob");

  if (options.jobid) {
    url = `${url}?jobId=${options.jobid}&account_id=${options.selectedAccountId}`;
  } else {
    url = `${url}?account_id=${options.selectedAccountId}`;
  }

  let response = axiosPrivate.post(url, options.payload);
  return response;
}

/**
 * This method is used for submitting JD
 * @param {*} options
 * @returns
 */
export async function submitAdminJob(options) {
  let url = getAPIMap("submitAdminJob");
  if (options.jobid) {
    url = `${url}?jobId=${options.jobid}&account_id=${options.selectedAccountId}`;
  } else {
    url = `${url}?account_id=${options.selectedAccountId}`;
  }

  let response = axiosPrivate.post(url, options.payload);
  return response;
}

/**
 * This method is used for listing Recruiter
 * @param {*} options
 * @returns
 */
export async function listRecruiter(options) {
  let url = getAPIMap("listRecruiter");
  url = url + "?account_id=" + options.queryKey[1];
  url = url + "&recruiter_type=" + options.queryKey[2];
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for listing Companies
 * @param {*} options
 * @returns
 */
export async function listCompanies(options) {
  let url = getAPIMap("listCompanies");
  //let url =
  //"https://curatal-dev.openturf.dev/curatal_recruiter/api/v1/admin/listcompany/withaccountId";
  let response = axiosPrivate.get(url);
  return response;
}

/**
 * This method is used for What's new api
 * @param {*} options
 * @returns
 */
export async function whatsNewGetAllCategotiesApiFunc() {
  
  let url = getAPIMap("whatsNewGetAllCategotiesApi");
  let response = axiosPrivate.get(url);
  return response;
}
export async function whatsNewGetRolesFunc() {
  
  let url = getAPIMap("whatsNewGetRoles");
  let response = axiosPrivate.get(url);
  return response;
}
export async function whatsNewApiFunc(options) {
  let url = getAPIMap("whatsNewApi");
  let response = axiosPrivate.post(url, options);
  return response;
}
//whatsNewRecuriter
export async function whatsNewRecuriterFunc() {
  
  let url = getAPIMap("whatsNewRecuriter");
  let response = axiosPrivate.get(url);
  return response;
}
//whatsNew Emoji Feedback

export async function whatsNewEmojiFeedback(options) {
  let url = getAPIMap("whatsNewFeedback");
  let response = axiosPrivate.post(url, options);
  return response;
}
// get all list whatsnew
export async function whatsNewGetAllfunc({ queryKey }) {
  let url = getAPIMap("whatsNewGetAll");
  //page=2&size=5
  url = `${url}?page=${queryKey[1]}&size=${queryKey[2]}`;
  let response = axiosPrivate.get(url);
  return response;
}
// Edit api  whatsNew
export async function whatsNewEditFunc(options,payload ) {
  let url = getAPIMap("whatsNewEdit");
  url = url.replace("id",options);
  let response = axiosPrivate.put(url,payload);
  return response;
}
export async function whatsNewDeleteFunc(options) {
  let url = getAPIMap("whatsNewDelete");
  url = url.replace("id", options);
  let response = axiosPrivate.delete(url, options);
  return response;
}
export async function whatsNewGetByIdfunc(options) {
  let url = getAPIMap("whatsNewGetById");
  //page=2&size=5
  url = url.replace("id", options);
  let response = axiosPrivate.get(url);
  return response;
}
